$defaultFontSize: 16px;
$font-roboto: 'Roboto', sans-serif;
$box-shadow: 0 2px 28px 0 gray;
$primary-color: #3287b2;
$primary-color-hover: #0280b3;
$blue-main: #7b96ac;

$menu-color: #252527;

$font-light: #fff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #524f4f;
$font-dark: #000;
$font-orange: #f8ab74;
$font-green: #88cb88;
$font-red: #cf3b3f;
$font-grey-ant: rgba(255, 255, 255, 0.65);
$font-gray: #676a6d;
$border-light: #fff;
$border-grey-light: #e8e8e8;
$border-grey: #808080;
$border-grey-dark: #595959;
$border-dark: #252527;
$border-green: #52c41a;

$background-light: #fff;
$background-grey-white: #f3f2f2;
$background-grey-light: #e8e8e8;
$background-grey: #808080;
$background-grey-dark: #414141;
$background-dark: #000;
$background-succes: #84b84c1f;
$background-error: #ff00001f;
$background-green: #88cb88;
$background-red: #cf3b3f;
$background-orange: #f8ab74;
$background-dark-web: #16202b;
$background-primary: #3287b2;
$background-main: rgb(90, 129, 145);
$background-blue-dark: #0D2F41;

$success: #84b84c;
$error: #f00;
$danger: #f54d50;
$yellow: #f5d700;

// Social Color
$youtube: #cd201f;
$twitter: #1da1f2;
$facebook: #3b5998;
$linkedin: #0077b5;

// Responsive down size
$media-breackpoint-down-xs: 413.98px;
$media-breackpoint-down-sm: 573.98px;
$media-breackpoint-down-md: 767.98px;
$media-breackpoint-down-lg: 991.98px;
$media-breackpoint-down-xl: 1199.98px;

// Responsive up size
$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 768px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1200px;

.ant-menu-item-selected {
    background-color: #7b96ac !important;
  }

.ant-menu{
    background: #0D2F41 !important;
    // background: #1b4289 !important; origigi
}
.ant-layout-header{
    background: #0D2F41 !important;
}