@import '../../../scss/index.scss';

.catalog {
    &__container {
        background-color: white;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0 3px 6px $font-grey-light;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;

        .header-top {
            &__left {
                h3 {
                    font-weight: 600;
                    font-family: 'Roboto', sans-serif;
                    color: $font-grey;
                }
            }

            &__right {
                text-align: right;
            }
        }
    }
    @media only screen and (max-width: $media-breackpoint-up-sm) {
        &__container {
            .header-top {
                &__left {
                    h2 {
                        text-align: left;
                    }
                }

                &__right {
                    text-align: left;
                }
            }
        }
    }
}
