@import '../../../scss/index.scss';

.ant-carousel{
.slick-dots-bottom {
    bottom: 24px;
}
}

.title-cards-home{
    font-size: 15px;
    font-weight: bold;
    color: #0D2F41;
    text-align: center;
    text-transform: uppercase;
}
.title-table{
    font-size: 17px;
    font-weight: bold;
    color: #0D2F41;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: -5px;
    text-transform: uppercase;
}
.subtitle-cards-home{
    font-size: 20px;
    font-weight: bold;
    color: #7b96ac;
    text-transform: uppercase; 
    text-align: center;
}